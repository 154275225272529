<template>
  <div class="container p-0">
    <JPIndex />
    <VNIndex />
    <CNIndex />
    <HKIndex />
    <TWIndex />
    <KRIndex />
    <LAIndex />
    <SGIndex />
    <GBIndex />
    <DEIndex />
    <RUIndex />
    <DJIndex />
  </div>
</template>
<script>
import JPIndex from './components/JPIndex.vue'
import VNIndex from './components/VNIndex'
import CNIndex from './components/CNIndex.vue'
import HKIndex from './components/HKIndex.vue'
import TWIndex from './components/TWIndex.vue'
import KRIndex from './components/KRIndex.vue'
import LAIndex from './components/LAIndex.vue'
import SGIndex from './components/SGIndex.vue'
import GBIndex from './components/GBIndex.vue'
import DEIndex from './components/DEIndex.vue'
import RUIndex from './components/RUIndex.vue'
import DJIndex from './components/DJIndex.vue'

export default {
  components: {
    JPIndex,
    VNIndex,
    CNIndex,
    HKIndex,
    TWIndex,
    KRIndex,
    LAIndex,
    SGIndex,
    GBIndex,
    DEIndex,
    RUIndex,
    DJIndex
  }
}
</script>
<style lang="scss">
.container {
  //width: 1000px;
  background-color: #FFFFFF;
  //padding: 0 15px;
}
</style>
